export const bookingZendeskTemplate = ({
  mentalHealthState,
  userData,
  addresses,
}) => {
  const {
    specificProblemIssue,
    causeOfDistress,
    hasConsultedWithPsychInProgram,
    hasConsultedWithPsychOutsideProgram,
    hasBeenHospitalized,
    isTakingPsychMeds,
    isTakingNonPsychMeds,
    hasDrugAllergies,
    hasBeenDiagnosed,
    specificProblemIssueDetails,
    previousPsychName,
    psychMedsDetails,
    nonPsychMedsDetails,
    drugAllergiesDetails,
    mentalIllnessDiagnosis,
    kesslerNervous,
    kesslerHopeless,
    kesslerRestless,
    kesslerDepressed,
    kesslerEffort,
    kesslerWorthless,
    hasWishedToBeDead,
    hadSuicidalThoughts,
    hasThoughtSuicidalMethod,
    hadSuicidalIntent,
    hadSuicidalIntentWithPlan,
    hasActedOnSuicidalThoughts,
    firstChoiceSchedule,
    secondChoiceSchedule,
    thirdChoiceSchedule,
    hasUsedGoogleMeet,
    otherNotes,
  } = mentalHealthState

  const {
    firstName,
    lastName,
    birthday,
    mobileNumber,
    email,
    employeeNumber,
    site,
    department,
    emergencyContact,
  } = userData

  const address = addresses?.addresses?.find((address) => address.primary)

  const printDateValue = (date) => {
    if (!date.month.value || !date.date.value || !date.year) return "N/A"
    return `${date.month.value} ${date.date.value} ${date.year}`
  }

  let personalDetailsBody = `- Personal Details -
  First Name: ${firstName}
  Last Name: ${lastName}
  Birthday: ${printDateValue(birthday)}
  Mobile Number: ${mobileNumber}

  - Address -
  Street Address: ${address?.streetAddress}
  City: ${address?.city}
  Province: ${address?.province}

  - Employee Information -
  Work Email: ${email}
  Employee Number: ${employeeNumber}
  Site: ${site?.label || "N/A"}
  Department: ${department}

  - Emergency Contact -
  Name: ${emergencyContact?.name || "N/A"}
  Mobile Number: ${emergencyContact?.mobileNumber || "N/A"}
  Relationship to Emergency Contact: ${emergencyContact?.relationship || "N/A"}`

  let cssrsBody = `Have you actually had any thoughts of killing yourself? ${hadSuicidalThoughts}\nHave you been thinking about how you might do this? ${hasThoughtSuicidalMethod}\nHave you had these thoughts and had some intention of acting on them? ${hadSuicidalIntent}\nHave you started to work out or worked out the details of how to kill yourself? Do you intend to carry out this plan? ${hadSuicidalIntentWithPlan}\nHave you ever done anything, started to do anything, or prepared to do anything to end your life? ${hasActedOnSuicidalThoughts}`

  let kessler6Body = `In the past month, have you felt nervous? ${kesslerNervous}\nHopeless? ${kesslerHopeless}\nRestless or fidgety? ${kesslerRestless}\nSo depressed that nothing could cheer you up? ${kesslerDepressed}\nEverything needed effort to accomplish? ${kesslerEffort}\nWorthless? ${kesslerWorthless}`

  // let personalDetailsBody = `- Personal Details -\nName: ${firstName} ${lastName}\nBirthday: ${birthday.month.value} ${birthday.date.value} ${birthday.year}\nGender: ${gender}\nMobile Number: ${mobileNumber}\nPersonal Email: ${personalEmail}`

  // let employeeInformationBody = `- Employee Information -\nWork Email: ${workEmail}\nEmployee Number: ${employeeNumber}\nSite: ${site.label}\nAccount/Department: ${department}\n\n- Emergency Contact -\nName: ${emergencyContact.fullName}\nMobile Number: ${emergencyContact.mobileNumber}\nRelationship to Emergency Contact: ${emergencyContact.relationshipToPatient}`

  let medicalHistoryBody = `- Medical History -\nRaised Concern: ${
    specificProblemIssue === "Yes"
      ? specificProblemIssueDetails
      : specificProblemIssue
  }\nCause of Distress: ${causeOfDistress}\nSeen a MHP outside MedGrocer: ${hasConsultedWithPsychOutsideProgram}\nSeen a MedGrocer MHP: ${
    hasConsultedWithPsychInProgram === "Yes"
      ? previousPsychName
      : hasConsultedWithPsychInProgram
  }\nHospitalized for MH reasons: ${hasBeenHospitalized}\nHas taken/is currently taking psychiatric meds: ${
    isTakingPsychMeds === "Yes" ? psychMedsDetails : isTakingPsychMeds
  }\nIs taking non-psychiatric meds: ${
    isTakingNonPsychMeds === "Yes" ? nonPsychMedsDetails : isTakingNonPsychMeds
  }\nHas drug allergies: ${
    hasDrugAllergies === "Yes" ? drugAllergiesDetails : hasDrugAllergies
  }\nHad a previous MH diagnosis: ${
    hasBeenDiagnosed === "Yes" ? mentalIllnessDiagnosis : hasBeenDiagnosed
  }\nOther notes: ${otherNotes || "N/A"}`

  let scheduleBody = `- Availability -\nOption 1: ${
    firstChoiceSchedule?.day?.value
  }, ${firstChoiceSchedule?.time?.value}${
    secondChoiceSchedule?.day?.value
      ? `\nOption 2: ${secondChoiceSchedule?.day?.value}, ${secondChoiceSchedule?.time?.value}`
      : ""
  }${
    thirdChoiceSchedule?.day?.value
      ? `\nOption 3: ${thirdChoiceSchedule?.day?.value}, ${thirdChoiceSchedule?.time?.value}`
      : ""
  }\n\nUsed GMeet before: ${hasUsedGoogleMeet}`

  return `${personalDetailsBody}\n\n${medicalHistoryBody}\n\n- Self-assessment -
  ${kessler6Body}\nHave you wished you were dead or wished you could go to sleep and not wake up? ${hasWishedToBeDead}
  ${hasWishedToBeDead.includes("Yes") ? cssrsBody : ""}\n\n${scheduleBody}`
}
