import axios from "axios"

import {
  GATSBY_MH_ZENDESK_EMAIL,
  GATSBY_MH_ZENDESK_API_KEY,
  GATSBY_MH_ZENDESK_SUBDOMAIN,
  GATSBY_PBM_ZENDESK_EMAIL,
  GATSBY_PBM_ZENDESK_API_KEY,
  GATSBY_PBM_ZENDESK_SUBDOMAIN,
  GATSBY_PBM_WEBSITE_CODE,
  GATSBY_MH_WEBSITE_CODE,
  GATSBY_ZENDESK_BASE_URL,
  GATSBY_ZENDESK_API_KEY,
  GATSBY_ZENDESK_API_URL,
  GATSBY_ZENDESK_EMAIL,
} from "gatsby-env-variables"

export const zendesk = ({ module }) => {
  let credentials = {}

  switch (module) {
    case GATSBY_PBM_WEBSITE_CODE:
      credentials.EMAIL = GATSBY_PBM_ZENDESK_EMAIL
      credentials.API_KEY = GATSBY_PBM_ZENDESK_API_KEY
      credentials.SUBDOMAIN = GATSBY_PBM_ZENDESK_SUBDOMAIN
      break
    case GATSBY_MH_WEBSITE_CODE:
      credentials.EMAIL = GATSBY_MH_ZENDESK_EMAIL
      credentials.API_KEY = GATSBY_MH_ZENDESK_API_KEY
      credentials.SUBDOMAIN = GATSBY_MH_ZENDESK_SUBDOMAIN
      break
    case "enrollment":
      credentials.EMAIL = GATSBY_ZENDESK_EMAIL
      credentials.API_KEY = GATSBY_ZENDESK_API_KEY
      credentials.SUBDOMAIN = GATSBY_ZENDESK_API_URL
      break
    default:
      return
  }

  let zendeskKey = credentials.EMAIL + "/token:" + credentials.API_KEY
  let encryptedKey = Buffer.from(zendeskKey).toString("base64")

  let api = axios.create({
    baseURL: GATSBY_ZENDESK_BASE_URL,
    headers: {
      Authorization: "Basic " + encryptedKey,
      "Content-Type": "application/json",
      api_url: credentials.SUBDOMAIN,
    },
  })

  return api
}
