import { GATSBY_ENV } from "gatsby-env-variables"
import { zendesk } from "../../../../services/zendesk"
import { bookingZendeskTemplate } from "../utils/bookingZendeskTemplate"

import { GATSBY_ZENDESK_REQUEST_CUSTOM_FIELD_ID } from "gatsby-env-variables"

const TEST_ENV = GATSBY_ENV === "production" ? "" : "[TEST] "

const generateZendeskRequestTemplate = ({
  mentalHealthState,
  userData,
  addresses,
}) => {
  let tags = ["concentrix", "concentrix_mh", "new_request", "self"]
  const subject = `[Concentrix] Teleconsult Request`
  if (GATSBY_ENV !== "production") tags.push("test")

  // let mentalHealthRequestState = {
  //   ...mentalHealthState,
  //   firstName: userData?.firstName || "",
  //   lastName: userData?.lastName || "",
  //   mobileNumber: userData?.mobileNumber || "",
  //   birthday: userData?.birthday || "",
  //   site: userData?.site || "",
  //   department: userData?.department || "",
  //   employeeNumber: userData?.employeeNumber || "",
  //   emergencyContactName: userData?.emergencyContact?.name || "",
  //   emergencyContactNumber: userData?.emergencyContact?.mobileNumber || "",
  //   emergencyContactRelationship:
  //     userData?.emergencyContact?.relationship || "",
  //   address: addresses?.addresses?.find((address) => address.primary) || "",
  // }

  let mentalHealthRequestState = {
    ...mentalHealthState,
    ...userData,
  }

  return {
    request: {
      type: "request",
      subject: `${TEST_ENV}${subject}`,
      requester: {
        name: mentalHealthState?.email || "",
        email: mentalHealthState?.email || "it@medgrocer.com",
      },
      comment: {
        body: bookingZendeskTemplate({
          mentalHealthState,
          userData,
          addresses,
        }),
      },
      custom_fields: [
        {
          id: GATSBY_ZENDESK_REQUEST_CUSTOM_FIELD_ID,
          value: JSON.stringify(mentalHealthRequestState),
        },
      ],
      tags,
    },
  }
}

export const handleSubmitBooking = async ({
  mentalHealthState,
  callback,
  errorCallback,
  module,
  userData,
  addresses,
}) => {
  try {
    let bookingRequestTemplate = generateZendeskRequestTemplate({
      mentalHealthState,
      userData,
      addresses,
    })

    await zendesk({ module }).post("/requests", bookingRequestTemplate)

    if (callback) callback()
  } catch (error) {
    if (errorCallback) errorCallback(error)
  }
}
